
.AccountHoldingWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.AccountHoldingOperationBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    margin-top: 10px;
}

.AccountHoldingOperationBar button {
    font-size: 10px;
}

.AccountHoldingOperationBar .ant-divider, .ant-divider-vertical {
    margin: 0 10px !important;
    background-color: darkgray;
}

.AccountHoldingGrid {
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}
