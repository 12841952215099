/* Trade Dashboard */
.tradeDashboardWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 20px; 
}

.tradeGrid {
  overflow: hidden;
  /* flex-grow: 1; */
  height: 100%;
  width: 100%;
  /* max-width: 1950px; */
}

.tradeOperationBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
}

.tradeOperationBar button {
  margin-right: 10px;
  font-size: 10px;
}

.tradeReportEdit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 950px;
  margin-top: 20px;
}

.ag-theme-balham .unsubmitted {
  background-color: pink !important;
}

.ag-theme-balham .submitted-unprocessed {
  background-color: orange !important;
}

.ag-theme-balham .not-upload-proof {
  background-color: red !important;
}

.ag-theme-balham .abandoned-application {
  background-color: lightgray !important;
  color: gray !important;
}

.matched-valid-restricted-security {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.matched-valid-restricted-security table {
  width: 100%;
  margin-top: 10px;
}

.matched-valid-restricted-security thead th {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.matched-valid-restricted-security tbody td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
