.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.grid-wrapper {
  height: 100%;
}

.draft-value {
  /* text-align: right;
  color: red;
  font-weight: bold;
  font-style: italic; */
  background-color: red;
}

.confirmed-value {
  /* text-align: right;
  color: lightcoral;
  font-weight: bold;
  font-style: italic; */
  background-color: lightcoral;
}

.published-value {
  /* text-align: right;
  color: lightgreen;
  font-weight: bold;
  font-style: italic; */
  background-color: lightgreen;
}

.period-value {
  /* text-align: right;
  color: red;
  font-weight: bold;
  font-style: italic; */
  background-color: lightgreen;
}

.non-period-value {
  /* text-align: right;
  color: red;
  font-weight: bold;
  font-style: italic; */
  background-color: red;
}

.positive-button {
  background-color: #21ba45 !important;
  color: #ffffff !important;
}

.negative-button {
  background-color: #db2828 !important;
  color: #ffffff !important;
}

.negativeeee-button {
  background-color: #f019e5 !important;
  color: #ffffff !important;
}
.negativwww-button {
  background-color: #f09a19 !important;
  color: #ffffff !important;
}
.ag-theme-balham .MarkBackgroundGreen {
  background-color: #99FF99 !important;
}
.ag-theme-balham .MarkBackgroundRed {
  background-color: #FF8888 !important;
}
.ag-theme-balham .MarkBackgroundGray {
  background-color: lightgray !important;
}
.ag-theme-balham .MarkBackgroundYellow {
  background-color: yellow !important;
}
