.questionnaireDashboardWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 20px; 
}

 .questionnaireOperationBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #ffffff;
}

.questionnaireRequestGrid {
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    width: 100%;
}

.quiz-expired {
    background-color: #FF8888;
}
